let slideIndex = 0;
showSlides(slideIndex);

// Function to move to the next or previous slide
function plusSlides(n) {
  showSlides(slideIndex += n);
}

// Function to show slides and handle fade effect
function showSlides(n) {
  const slides = document.getElementsByClassName("carousel-slide");

  // Reset slideIndex if it's out of bounds
  if (n >= slides.length) { slideIndex = 0; }
  if (n < 0) { slideIndex = slides.length - 1; }

  // Hide all slides
  for (let i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }

  // Display the current slide with a fade effect
  slides[slideIndex].style.display = "block";
}

// Optional: Automatically move to the next slide every 5 seconds
setInterval(() => {
  plusSlides(1);
}, 5000);




document.addEventListener('DOMContentLoaded', () => {
    const headers = document.querySelectorAll('.accordion-header');
  
    headers.forEach(header => {
      header.addEventListener('click', () => {
        const contentId = header.getAttribute('data-target');
        const content = document.getElementById(contentId);
        const currentlyOpen = document.querySelector('.accordion-content.open');
  
        // If there's an open panel and it's not the one we're about to open, close it
        if (currentlyOpen && currentlyOpen !== content) {
          currentlyOpen.style.maxHeight = null;
          currentlyOpen.classList.remove('open');
        }
  
        // Toggle the selected panel
        if (content.classList.contains('open')) {
          content.style.maxHeight = null;
          content.classList.remove('open');
        } else {
          content.style.maxHeight = content.scrollHeight + 'px';
          content.classList.add('open');
        }
      });
    });
  });
  